import React from "react"
import { Link, graphql } from "gatsby"
import { Block } from "baseui/block"
import Container from "../components/UI/Container"
import { SEOPage } from "../components/SEO"
import Layout from "../components/layout"

const TagsPage = ({ data }) => {
  const { group } = data.allMdx
  return (
    <Layout isSimple>
      <Block paddingTop="190px" paddingBottom="100px" marginLeft={0}>
        <SEOPage title="Tags" description="Cylynx - Tags"/>
        <Container className="post-content">
          <h1> Tags </h1>
          <Block
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            marginBottom={0}
            marginTop="16px"
          >
            {group.map(tag => (
              <Link
                key={tag.fieldValue}
                to={`/tags/${tag.fieldValue}`}
                style={{
                  paddingRight: "30px",
                  fontWeight: 600,
                  color: "#1992D4",
                }}
              >
                {tag.fieldValue} ({tag.totalCount})
              </Link>
            ))}
          </Block>
        </Container>
      </Block>
    </Layout>
  )
}

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    allMdx(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
